
import convertDecimalWithComma from "@/app/infrastructures/misc/common-library/ConvertDecimalWithComma";
import { formatPhoneNumber } from "@/app/infrastructures/misc/Utils";
import { ShipmentBookingDetail } from "@/domain/entities/ShipmentBooking";
import { Options, prop, Vue } from "vue-class-component";

class Props {
  data = prop<ShipmentBookingDetail>({
    default: new ShipmentBookingDetail({}),
    type: ShipmentBookingDetail,
  });
}
@Options({
  components: {},
})
export default class SectionDetailShipmentRecipient extends Vue.with(Props) {
  convertDecimalWithComma(value: any) {
    return `${convertDecimalWithComma(value, 2)} kg`;
  }

  get phoneNumber() {
    return formatPhoneNumber(
      `${this.data.sttRecipientPhoneCode}${this.data.sttRecipientPhone}`
    ).replace("+62", "0");
  }

  get statusChipsStyle() {
    return {
      label: this.data.sttLastStatus?.toUpperCase(),
      color: "gray-lp-200",
      textColor: "black-lp-300",
    };
  }
}
