import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "relative bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen w-full" }
const _hoisted_2 = { class: "flex gap-x-6 text-20px border-b border-gray-lp-400 pt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.availableTabs, (tab) => {
        return (_openBlock(), _createBlock("div", {
          key: tab.name
        }, [
          (tab.permission)
            ? (_openBlock(), _createBlock("div", {
                key: 0,
                class: 
            `flex flex-row items-center justify-center self-center
            ${
              _ctx.isTabActive(tab.name)
                ? 'text-red-lp-100 border-b-2 border-red-lp-100'
                : 'text-gray-lp-600'
            }`
          
              }, [
                _createVNode("button", {
                  onClick: ($event: any) => (_ctx.changeTabs(tab.name)),
                  class: 
              `pb-4 font-semibold hover:text-red-lp-100 focus:outline-none`
            
                }, _toDisplayString(tab.title), 9, ["onClick"])
              ], 2))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createVNode(_component_router_view)
  ]))
}