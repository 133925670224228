
import { formatDateNormal } from "@/app/infrastructures/misc/Utils";
import { UrgentDeliveryManagementController } from "@/app/ui/controllers/UrgentDeliveryManagementController";
import { OptionsClass } from "@/domain/entities/MainApp";
import {
  UrgentDeliveryManagementRTSData,
  UrgentDeliveryManagementRTSListRequest,
  UrgentDeliveryManagementRTSListRequestDownload
} from "@/domain/entities/UrgentDeliveryManagement";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class UrgentRTS extends Vue {
  controller = UrgentDeliveryManagementController;
  parameters = {
    search: "",
    onSort: false,
    caseType: new OptionsClass(),
  };

  columns = [
    {
      name: "No",
      styleHead: "text-left head-no",
      render: (item: UrgentDeliveryManagementRTSData, i: number) =>
        `<span class="${
          this.getStyleByFlag(item.pdFlag).class
        } font-normal text-black-lp-300 whitespace-nowrap">${i +
          1 +
          this.pagination.limit * (this.pagination.page - 1)}</span>`
    },
    {
      name: "No.STT",
      styleHead: "text-left head-no-stt",
      render: (item: UrgentDeliveryManagementRTSData) =>
        `<div class="flex flex-col gap-1 text-sm text-black-lp-100">
          <div>
            ${item.pdSTTNo}
          </div>
          ${item.pdFlag ? 
            `<div class="flex flex-row gap-1">
              <img src="${require(`@/app/ui/assets/svg/${
                this.getStyleByFlag(item.pdFlag).icon
              }`)}" class="text-red-lp-100" width="16" />
              <div class="font-medium ${
                this.getStyleByFlag(item.pdFlag).textClass
              }">${item.pdFlag}</div>
            </div>` : ""
          }
        </div>`
    },
    {
      name: "Batas akhir RTS",
      styleHead: "text-left head-deadline",
      render: (item: UrgentDeliveryManagementRTSData) =>
        `<div class="font-normal text-sm text-black-lp-100">${this.getDeadlineFormatDate(
          item.pdDeadlineReturn
        )}</div>`
    },
    {
      name: "Tipe kasus",
      styleHead: "text-left head-case-type",
      render: (item: UrgentDeliveryManagementRTSData) =>
        `<div class="font-normal text-sm badge">${item.pdCaseType}</div>`
    },
    {
      name: "Alasan",
      styleHead: "text-left head-reason",
      render: (item: UrgentDeliveryManagementRTSData) =>
        `<div class="font-normal text-sm text-black-lp-100">${item.pdReason}</div>`
    },
    {
      name: "Kota Asal",
      styleHead: "text-left head-origin",
      render: (item: UrgentDeliveryManagementRTSData) =>
        `<div class="font-normal text-sm text-black-lp-100">${item.sttOriginCityId} - ${item.sttOriginCityName}</div>`
    },
    {
      name: "Status terakhir",
      styleHead: "text-left head-last-status",
      render: (item: UrgentDeliveryManagementRTSData) =>
        `<div class="font-normal text-sm text-black-lp-100">${item.sttLastStatusId}</div>`
    },
  ];

  mounted(): void {
    this.fetchList(true);
  }

  fetchList(reset = false): void {
    if (reset) {
      this.controller.paginationData.page = 1;
    }

    this.controller.getRTSList(
      new UrgentDeliveryManagementRTSListRequest({
        sortBy: this.parameters.onSort ? "asc" : "desc",
        sttNo: this.parameters.search,
        caseType: this.parameters.caseType.value,
        page: this.controller.paginationData.page,
        limit: this.controller.paginationData.limit
      })
    );
  }

  onSearch(value:string) {
    if (!value.length || value.length >= 3) {
      this.parameters.search = value;
      this.fetchList(true);
    }
  }

  clearSearch() {
    this.parameters.search = "";
    this.fetchList(true);
  } 


  getStyleByFlag(
    flag: string
  ): {
    class: string;
    icon: string;
    textClass: string;
  } {
    if (flag.toLowerCase().includes("hari ini")) {
      return {
        class: "today",
        icon: "clock-normal-red.svg",
        textClass: "text-red-lp-100"
      };
    }
    if (flag.toLowerCase().includes("lewat")) {
      return {
        class: "overdue",
        icon: "clock-exclamation.svg",
        textClass: "text-yellow-lp-100"
      };
    }
    else {
      return {
        class: "normal",
        icon: "clock-normal.svg",
        textClass: "text-gray-lp-500"
      };
    }
  }

  getDeadlineFormatDate(date: string): string {
    const currentDate = new Date().toISOString();

    if (formatDateNormal(currentDate) === formatDateNormal(date)) {
      return "Hari Ini";
    } else return formatDateNormal(date, "DD MMMM YYYY");
  }

  get sortButtonStyle() {
    return this.parameters.onSort
      ? {
          textColor: "red-lp-300",
          customClass: "w-full mr-4 bg-red-lp-1300 btn-active",
          color: "red-lp-300"
        }
      : {
          textColor: "gray-lp-500",
          customClass: "w-full mr-4",
          color: ""
        };
  }

  get emptyStateData(): {
    headerMessage: string;
    message: string;
    icon: string
  } {
    if (this.parameters.search.length) {
      return {
        headerMessage: "Filter tidak ditemukan",
        message: "Cek dan atur ulang filter yang Anda cari.",
        icon: "noData_filter"
      }
    } else {
      return {
        headerMessage: "Belum ada kiriman penting",
        message: "Yuk! Mulai masukkan/scan nomor STT pada menu STI DEST.",
        icon: "noData_empty"
      }
    }
  }

  // caseType
  get caseTypeData(): OptionsClass[] {
    return [
      new OptionsClass({
        name: "DEX",
        value: "DEX"
      }),
      new OptionsClass({
        name: "CODREJ",
        value: "CODREJ"
      }),
      new OptionsClass({
        name: "Permintaan Customer",
        value: "Permintaan Customer"
      }),
    ];
  }

  get pagination() {
    return this.controller.paginationData;
  }

  selectCaseType(data: OptionsClass) {
    Object.assign(this.parameters, { caseType: data });
    this.fetchList(true);
  }


  async onDownload() {
    await this.controller.downloadUrgentRTSList(
      new UrgentDeliveryManagementRTSListRequestDownload({
        sortBy: this.parameters.onSort ? "asc" : "desc",
        sttNo: this.parameters.search,
        caseType: this.parameters.caseType.value,
      })
    );
  }
}
