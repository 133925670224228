import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"
import _imports_0 from '@/app/ui/assets/svg/product-logo/jumbopack-active.svg'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  src: _imports_0
}
const _hoisted_3 = { class: "flex flex-col pb-6 w-full" }
const _hoisted_4 = { class: "flex flex-row gap-x-4 mt-4 p-4 border border-gray-lp-1400 rounded-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionDetailShipmentRecipient = _resolveComponent("SectionDetailShipmentRecipient")!
  const _component_SectionRequirementPhoto = _resolveComponent("SectionRequirementPhoto")!
  const _component_SectionUploadPOD = _resolveComponent("SectionUploadPOD")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!
  const _directive_sanitize_html = _resolveDirective("sanitize-html")!

  return (_openBlock(), _createBlock(_component_DetailLayout, {
    scrollSidebar: "",
    customClass: "px-0",
    fullPage: "",
    onBack: _ctx.goBack,
    title: _ctx.detailData.sttNo,
    loading: _ctx.isLoading,
    error: _ctx.isError,
    errorType: _ctx.errorCause,
    onTryAgain: _ctx.fetchDetail,
    verticalSeparator: false
  }, _createSlots({
    data: _withCtx(() => [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_SectionDetailShipmentRecipient, { data: _ctx.detailData }, null, 8, ["data"]),
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_SectionRequirementPhoto),
          _createVNode(_component_SectionUploadPOD, { data: _ctx.detailData }, null, 8, ["data"])
        ])
      ])
    ]),
    _: 2
  }, [
    (!_ctx.isError)
      ? {
          name: "title",
          fn: _withCtx(() => [
            (_ctx.detailData.sttIsCod)
              ? _withDirectives((_openBlock(), _createBlock("div", _hoisted_1, null, 512)), [
                  [_directive_sanitize_html, _ctx.handleStatusSTTCOD()]
                ])
              : _createCommentVNode("", true),
            (
          _ctx.detailData.sttProductTypeName.toUpperCase() === 'JUMBOPACK' ||
            _ctx.detailData.sttProductTypeName.toUpperCase() === 'JUMBOPACKH2H'
        )
              ? (_openBlock(), _createBlock("img", _hoisted_2))
              : _createCommentVNode("", true)
          ])
        }
      : undefined
  ]), 1032, ["onBack", "title", "loading", "error", "errorType", "onTryAgain"]))
}