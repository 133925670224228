
import { FlagPermissionUrgentDeliveryManagement } from "@/feature-flags/flag-permission-urgent-management";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  computed: {},
})
export default class UrgentDeliveryManagement extends Vue {
  tabs = [
    {
      name: "urgent-pod",
      title: "Segera POD",
      permission: FlagPermissionUrgentDeliveryManagement.permission_urgent_delivery_management_pod_enable.isEnabled(),
    },
    {
      name: "urgent-rts",
      title: "Segera RTS",
      permission: FlagPermissionUrgentDeliveryManagement.permission_urgent_delivery_management_rts_enable.isEnabled(),
    },
    {
      name: "urgent-hal",
      title: "Segera HAL",
      permission: FlagPermissionUrgentDeliveryManagement.permission_urgent_delivery_management_hal_enable.isEnabled(),
    },
    {
      name: "taken-by-recipient",
      title: "Diambil Penerima",
      permission: true,
    },
  ];
  changeTabs(value: string) {
    this.$router.push({
      name: value,
    });
  }

  get availableTabs() {
    return this.tabs.filter((tab) => tab.permission);
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }

  mounted() {
    // to do
  }
}
